@import '../../../../../globals/variables.scss'; 
@media screen and (max-width: 992px) {

   .footer {
       background-color: whitesmoke;
       padding: 0 0;
       color: black;
        .innerFooter {
            width: 100%;
            max-width: $wrapperMaxWidth;
            margin: 0 auto;  
            .innerFooterContent {
                flex-direction: column;
            }
            p {    
                a {   
                    display: inline-block;
                    margin-right: 20px; 
                } 
            }
       } 
   }


    @media screen and (max-width: 992px) { 
        .headerBorder {
            ul {
                flex-direction: column;
                li{ 
                    line-height: 1.8;
                }
            }
        } 
    }
}
