@import '../../../globals/variables.scss';
 

.twoColContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div{
        width: 50%;
        padding: 50px 15px;
        text-align: left;
        img{
            max-width: 80%;
        }
        p, h2{
            text-align: left;
        }
        h2{
            font-size: 32px;

        }
    }
}