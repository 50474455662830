@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&display=swap');
 
html {
  overflow-x: hidden;
  max-width: 100vw;
}

body { 
  overflow: hidden;
  max-width: 100vw; 
  max-height: 100%;
  margin: 0;
  padding: 0;  
  font-family: 'Montserrat', sans-serif;

  * {
    font-family: 'Montserrat', sans-serif;

  }
}