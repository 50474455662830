@import '../../../globals/variables.scss';


.centralContainer{
    text-align: center;
    margin: 90px 0;

    .titleContainer{
        h2{
            font-size: 38px;
        }
        p{
            color: rgb(78, 86, 82);
        }
    }
    .threePoints{
        background-color: $secondaryFusion;
        border-radius: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 50px;
        margin-top: 50px;
        margin-bottom: 50px;
        > div{
            padding: 0px 20px;
            span{
                margin: 0 auto;
                font-size:80px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                background-color: white;
                color: $secondaryFusion;
            }
            p{

            &:first-of-type{
                margin-top: 40px;
                font-size: 25px;;
                font-weight: 600;
                color:white;
            }
            &:last-of-type{
                color:white;
                line-height: 2.3;
            }
            }
        }
    }   
}