@import '../../../../../globals/variables.scss'; 

.footer{ 
    .innerFooter{   
        background-color: whitesmoke;
        padding: 50px 0;
        color: $primary;
        .innerFooterContent{ 
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            .disclaimerContainer{ 
                text-align: left;;
                p{
                    font-size: 15px;
                }
            }
            .logoContainer{ 
                margin-right: 50px;
                img{ 
                    width: 300px; 
                }   
            }
        }
    }  
    .footerPolicyLinks{   
        background-color: $primary;
        padding: 10px 0;
        color: white;
        border-top: 1px solid white;
        p{
            font-size: 15px;
            a{
                margin: 0 10px;
                cursor: pointer;
                text-decoration: none;
                color: white;
                &:hover{
                    text-decoration: underline;
                }
            }
        } 
    }
    .footerbottom {
        background-color: $blueRebrand;
        padding: 10px;
        p{
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            a{
                text-decoration: none;
                color: white;
                span{
                    font-weight: 600;

                }
            }
        }
    }
}
 
.headerBorder{
    padding: 10px;
    background-color: $secondaryFusion;
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;;
        justify-content: center;
        li{
            font-size: 12px;
            color: white;
            text-transform: uppercase;
            margin: 0 40px;
            padding: 0;
            // font-weight: 600;
            letter-spacing: 1px;
            vertical-align: middle;
            span{
                font-size: 16px;    
            }

            &:before {
                content: '';
                height: 15px;
                width: 15px;
                display: inline-block;
                margin-right: 10px;
                background-size: contain;
                vertical-align: text-top;
            }
            &:first-of-type:before {
                background-image: url('../../../../../assets/fusionSign/lock.svg');
            }
            &:nth-of-type(2):before {
                content: none;
             }
            &:nth-of-type(3):before {
                background-image: url('../../../../../assets/fusionSign/currency.svg');
            }
            img{
                max-height: 21px;
                vertical-align:text-bottom;
                margin-right: 5px;
            }
        }
    }
}