@import '../../../globals/variables.scss';
 
.centralContainer{
    background-color: $secondaryFusion;
    color: white;
    padding: 60px 0;
    margin: 40px 0;
    .tagline{
        font-weight: 600;
        margin: 20px auto 40px;
        font-size: 20px;
    }
    .titleContainer h2{
        font-size: 30px;
        margin: 0;
    }
    .twoColContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        > div{
            width: 50%;
            padding: 50px 15px;
            text-align: left;
            img{
                max-width: 80%;
            }
            p, h2{
                text-align: left;
            }
            h2{
                font-size: 32px;
            }
            p{
                font-size: 17   px;
                margin-bottom: 25px;
                line-height: 1.8;
            }
            ul{
                li{
                    line-height: 1.8;
                    margin-bottom: 25px;
                    list-style: none;;
                    position: relative;
                    font-weight: 500;
                    &:before{
                        content: '';
                        height: 20px;
                        width: 20px;
                        left: -30px;
                        display: block; 
                        position: absolute;;
                        background-image: url('../../../assets/tick2.png');
                        background-color: transparent;
                        background-position: center;
                        background-size: contain;
                    }
                }
            }
        }
    }
}