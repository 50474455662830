@import '../../../globals/variables.scss';
 

.twoColContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div{
        width: 50%;
        padding: 50px 15px;
        text-align: left;
        img{
            max-width: 80%;
        }
        p, h2{
            text-align: left;
        }
        h2{
            font-size: 32px;

        }
    }
    .pointsContainer{
        position: relative;
        padding-left: 30px;
        .points{
            position: absolute;
            top: 50px;
            left: 0;
            bottom: 50px;
            background-color: black;
            color: #4e5652;
            width: 1px;
            > span{
                height: 15px;
                width: 15px;
                position: absolute;
                left: 0;;
                background-color: #333;
                border-radius: 25px;
                transform: translateX(-50%);
                border: 10px solid whitesmoke;
                &:nth-of-type(1){
                    top: 31%
                }
                &:nth-of-type(2){
                    top: 62%

                }
                &:last-of-type{
                    bottom: 0
                }
            }
        }
       
    }
    ul{
        list-style-type: none;
        padding: 0;
        li{
            border: 1px solid lightgrey;
            padding: 30px;
            background-color: rgba(90, 134, 195, 0.14);

            border-radius: 8px;
            margin-bottom: 20px;
            font-size: 15px;
            span{
                display: block;
                font-weight: 700;;
                margin-bottom: 15px;
                font-size: 24px;
            }
        }
    }
    .yellowContainer{
        background-color: rgb(238, 238, 221);
        text-align: center;
        padding: 40px;
        border-radius: 50px;
        p{
            color: rgb(78, 86, 82);
            text-align: center;
            line-height: 1.8;
            margin-bottom: 25px;
        }
    }
}