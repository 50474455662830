@import '../../../globals/variables.scss';


.hereToHelpContainer{
    text-align: center;
    .titleContainer{
        h2{
            font-size: 38px;
        }
        p{
            color: rgb(78, 86, 82);
        }
    } 
    p{

        line-height: 1.8;
        padding-left: 30px;
        position: relative;
        max-width: 80%;
        margin: 0 auto 50px;
        img{  
            width: 15px;
            height: 15px;
            margin-right: 5px;
            margin-bottom: -2px;
        }
    }
}