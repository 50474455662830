.uploadSections{
    margin-bottom: 40px;
    h3{
        margin: 0;
        font-size: 20px;
        font-weight:400;
    }
    > p{
        font-size: 14px;
        max-width: 75%;
        margin: 10px auto 0;
    }
      @media screen and (max-width: 992px) {
        margin-bottom: 0; 
        >p{
            font-size: 12px;
            max-width: 100%;
            margin: 10px 0;
        }
    }
}
.uploadSection{
  
    margin-bottom: 40px;  
    .site{
        border-bottom: 1px solid grey;
        padding: 20px 0; 
        &:last-of-type{
            border:none;
            padding: 0;    
        } 
        > h4{
            text-align: left;
            font-size: 20px;
            margin:10px 0;
            font-weight: 500;
        }
        .siteInnerContainer{ 
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap; 
            text-align: left;
        }
        .files{
            width: 50%;
            display: flex;
            justify-content: space-between;
            >*{ 
                margin-right: 20px;
            }

 
        }
        .breakdown{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: left;
            div{
                h5{
                    margin: 25px 0 5px; 
                    font-size: 18px; 
                    font-weight: 500;
                } 
            }
            ul{
                margin: 0 0 10px;
                list-style-type: none;
                padding: 0;
                li{
                    margin-bottom: 3px;
                }

            }
        }
        
    }
    @media screen and (max-width: 992px) { 
        .site{
             .siteInnerContainer{ 
                align-items: flex-start;
                justify-content: space-between;
                text-align: left;
                flex-direction: column;
                
            }
             .breakdown{
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
             }
            .files{
                width: 100%; 
                flex-direction: column; 
    
            }
        }
    }
}