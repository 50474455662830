@import '../../../globals/variables.scss';

.inputSelectStyle{ 
    width: $inputWidth;
    display: block;
    max-width: $inputWidthMax;
    line-height: 1;  
    font-size: 16px;
    padding: 0px;
    border-radius: 5px;
    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width:  100%;
        box-sizing: border-box;
    }
} 

span.notice{
    display: block;
    color: red; 
    font-size: 11px;
}