@import '../../../globals/variables.scss';


.formContainer{
    // background-color: white;
    // padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    flex-direction: column;
    max-width: 450px;
    .formTitle{
        margin: 20px 0 ;  
        h1{
            color: $primary;
            margin:0;
            line-height:1;
            font-weight: 400; 
        }
    } 
    .form{ 
        margin: 0 auto;
        width: 100%;     
    }
    
    .progressContainer{
        margin:30px auto;
        position: relative;
        width: 80%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        // margin-bottom: 50px;
        .progressBar{
            height: 5px;
            position: absolute;
            left:5%;
            right: 5%;
            top: 50%;
            transform:translateY(-50%);
            background-color: white;
            // border: 1px solid $primaryFusion;
            .progressBarContent{
                border-radius: 25px;
                background-color: $primaryFusion;
                position: relative;
                display: block;
                width: 25%;
                height: 100%;
                transition: ease-in-out 600ms all;
            
            }
        }
        .progressItem{
            width: 30px;
            height: 30px;

            &.complete p span:first-of-type{
                color: white;
                background-color: $secondaryFusion;
                border: 1px solid $secondaryFusion;
            }
            p{
                margin: 0;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 100%;
                span{
                     &:first-of-type{
                        font-size: 20px;
                        display: inline-block;
                        position: relative;
                        border-radius: 25px;
                        width: 30px; 
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $primary;
                        background-color: white;
                        // border: 1px solid $primaryFusion;
                    }
                    &:last-of-type{
                        display: none;
                        font-size: 12px;
                        color: white;
                        position: absolute;
                        top: 100%;
                        margin-top: 10px;
                        left: 50%;
                        transform:translateX(-50%);
                    }
                }
            }
        }
    }

}