.field{
    margin: 0 0 20px;
    padding: 0 5px;

    label{
        display: block; 
        text-align: center;
        font-size: 18px;
        max-width: 550px;
        margin: 0 auto 10px;
        &.left{
            margin: 0 auto 5px;
            text-align: left;
        }
        &.right{
            margin: 0 auto 5px;
            text-align: right;
        }
    }
    @media screen and (max-width: 992px) {
        margin: 0px 0 0;  
        label{ 
            // margin: 0 auto 0px!important;
        }
    }
}