@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) { 
    .twoColContainer{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        > div{
            width: 100%;
            padding: 20px 15px;
            text-align: left; 
        } 
    }
}