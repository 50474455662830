@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) { 
    .formContainer{
        .formTitle{
            margin: 10px 0; 
            p{ 
                line-height: 1.7; 
                padding: 3px 15px; 
                font-size: 12px; 
            } 
            h1{
                font-size: 19px;
                margin-bottom: 0;
            }
        }
        .progressContainer{
            margin: 10px 0 15px;
            .progressItem{
                p{ 
                    span{
                        &:first-of-type{
                            font-size: 15px; 
                            width: 40px; 
                            height: 40px;
                        }
                    }
                }
            }
            .progressBar{
                height: 5px;
                top: 34px; 
            }
        }
    }
}