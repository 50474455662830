.modalOuter{
    position: fixed;
    left: 0 ;
    top: 0;
    right: 0;
    bottom:0;
    background-color: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index:-1;

    &.displayModal{
        opacity: 1;
        pointer-events: all;
        z-index:9999;
        transition: ease-in-out 200ms all;
    }
    >div{
        position: relative;
        max-width: 85%;
        max-height: 85%; 
        > div{ 
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
            padding: 10px;
            background-color: whitesmoke;
            border-radius: 4px;
            box-shadow: 8px 6px 5px 0px  rgba(0,0,0,.4);
            > *{ 
                max-height: 80vh; 
                max-width: 100%; 
                display: block;
                position: relative; 
            }
        }
    }
    button{
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        top: -55px;
        right: 0px;
        border: none;;
        width: 50px;
        height: 50px; 
        > *{
            max-width: 100%;;
            max-height: 100%;;
        }
    }
}