@import '../../../globals/variables.scss';

.formCard{ 
    box-sizing: border-box;
    padding: 20px;
    background: white;
    border-radius: 8px;
    // border: 1px solid $primaryFusion;
    box-shadow: 1px 4px 6px -1px rgba(0,0,0,.4); 
    // transition:ease-in-out 500ms all;
    transform:translateY(60px);
    opacity:0;
    width: 100%;
    box-sizing: border-box;
    &.showFormCard{
        opacity:1;
        transition:ease-in-out 350ms all;
        transform:translateY(0);
        // animation: 1000ms 1 forwards showCard;

    }
    &.hideFormCard{
        opacity:0;
        transition:ease-in-out 350ms all;
        transform:translateY(-30px); 
        // animation: 500ms 1 forwards hideCard;

    }
    @media screen and (max-width: 992px) {
        padding: 15px 15px; 


    }
}
  