@import '../../../globals/variables.scss';


.clientNumbers{
    text-align: left;
    padding: 50px 0;
    margin: 40px 0;
    background-color: whitesmoke;
}

.clientNumbersInner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 30px;
    box-sizing: border-box;
    span{
        width: 100%;
        font-size: 16px;
        position: absolute;
        bottom: 0;
        color: grey;
        left: 0;
        font-weight:500;
    }
    p {
        margin:0 0 ;
        position: relative;
        width: 30%;
        font-size: 48px;
        list-style: 2.2;
        font-weight: 600; 
        padding: 0 0 30px; 
        color: #333;
        &:first-of-type{
        font-size: 40px;
            width: 40%;
            padding: 30px 0 0 ;
            span{
                text-transform: uppercase;
                letter-spacing: 2px;;
                color: $secondaryFusion;
                top: 0;
                font-weight: 700;
            }
        }


    }
    img{
        max-width: 15%;   
    }
}