@import '../../../globals/variables.scss';


@media screen and (max-width: 992px) { 
    .clientNumbers{
        text-align: left;
        padding: 30px 0;
        margin: 30px 0;
        background-color: whitesmoke;
    }

    .clientNumbersInner{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0  ;
        box-sizing: border-box;
        span{
            width: 100%;
            font-size: 16px;
            position: absolute;
            bottom: 0;
            color: grey;
            left: 0;
            font-weight:500;
        }
        p { 
            margin: 15px 0;
            position: relative;
            width: 100%;
            font-size: 38px;
            list-style: 1.7;
            font-weight: 600; 
            padding: 0 0 20px; 
            color: #333;
            &:first-of-type{
                font-size: 35px;
                width: 100%;
                padding: 20px 0 0;
                span{
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: $primary;
                    top: 0;
                    font-weight: 700;
                }
            }


        }
        img{
            max-width: 15%;   
        }
    }
}