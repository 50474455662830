@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) { 
    .faq{ 
        h2{
            text-align: center;
            font-size: 28px;
            margin-bottom: 40px;;
        }
        > div{
            width: 100%;
            padding: 30px 15px;
            text-align: left;
            .faqItem{
                background-color: $blueRebrand;
                color:white;   
                margin-bottom: 20px;
                cursor: pointer;
                div{
                    pointer-events: none;;
                    &:first-of-type{
                        p{
                            padding: 15px; 
                            font-size: 16px;
                            font-weight: 700;
                            margin: 0;
                        }
                    }
                    &:last-of-type{
                        height: 0;
                        overflow: hidden;
                        p{
                            padding: 20px; 
                            background-color: rgb(66, 66, 66);
                            font-size: 15px;
                            margin: 0;
                            line-height: 1.8;
                        }
                    }
                }
            
            }
        } 

    }
}