@import '../../globals/variables.scss';
.banner{ 
    background-image: url('../../assets/foa-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 0 50px;
    position: relative;
    z-index: 0;
    margin-bottom: 30px;
    &:before{
        content: "";
        background-color: rgba(0,0,0,.5);
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;

    }
    > div{
        position: relative;
        h1, p{
            font-weight: 500;
            color: white;
            line-height: 1.2
        }

    }
}