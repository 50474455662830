@import '../../../globals/variables.scss';

.inputStyle{  
    width: $inputWidth;
    max-width:  $inputWidthMax; 
    display: block;
    line-height: 1.6;
    box-sizing: border-box;
    font-size: 17px;
    padding: 5px 5px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    margin: 0;

    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width:  100%;
        box-sizing: border-box;
    }
} 

span.notice{
    display: block;
    color: red; 
    font-size: 11px;
}