.sidebyside{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    > *{
        width: 48%;

    }

    @media screen and (max-width: 992px) {
        flex-direction: column;
        max-width: 100%;
        > * , > * > *{
            width: 100%;
            max-width: 100%!important;
        }
    }
}