@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
    .greenBar{
        text-align: left;
        background-color: whitesmoke;;
        padding: 30px 0;

    }
    .greenBarInner{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column; 
        box-sizing: border-box;
        padding: 0;
        text-align: center;
        p {
            margin: 0;
            max-width: 100%;
            font-size: 21px;
            font-weight: 600;
            color: $secondaryFusion;

        }
        img{
            margin: 10px 0;
            max-width: 120px;   
        }
    }
}