@import '../../../globals/variables.scss';

.firmContainerContent{
    padding: 30px 0;
    text-align:left;
    background-color: white;
    .explainerContent{
        h2{
            margin: 0 0 15px;
            padding-bottom: 15px;
            font-weight: 400;
            border-bottom: 1px solid lightgrey;
        }
        p{
            line-height: 1.6;
            letter-spacing: 0.5px;
            color: #333;
        } 
    }
    .previewsContainer{
        width: 100%;
        display: block;
        position: relative;
        margin: 30px 0 ;
        h2{
            margin: 0 0 15px;
            padding-bottom: 15px;
            font-weight: 400;
            border-bottom: 1px solid lightgrey;
        }
        .previews{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 50px 0 20px;
            .singlePreview{
                .innerSinglePreview{
                    border-radius: 9px;
                    overflow: hidden;;
                    border: 3px solid $blueRebrand;
                    padding: 0;
                    height: 450px;
                    display: flex;
                    flex-direction: column;
                    > iframe{
                        height: 100%;
                    }
                }
                p{
                    text-align: center;
                    font-size: 13px;
                    font-style: italic;
                    margin-top: 5px;
                    a{
                        text-decoration: none;
                        color: $blueRebrand;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        
        }
    }
}