@import '../../../globals/variables.scss';
.banner{ 
    background-image: url('../../../assets/sign-doc.jpg');
    background-position: 0% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0;
    position: relative;
    z-index: 0;
    margin-bottom: 0;
    &:before{
        content: "";
        background-color: rgba(0,0,0,.7);
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
    }
    > div{
        position: relative;
        text-align: left;
        h1{
            margin-bottom: 15px;
            letter-spacing: 1px;
            line-height: 1.3;
            span{
                font-size: 28px;
                font-weight: 400;
            }
        }
        p{
            font-weight: 300;
            line-height: 1.5
        }
        h1, p{
            color: white;
        }

    }
}