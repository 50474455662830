@import './variables.scss';

.pageContent{
    padding: 50px 0 0;
    margin: 0;
    > div{
        // padding-top: 90px; 
    }
    p, ul li, a{
        font-size: 17px;
        @media screen and (max-width: 992px) { 
            font-size: 15px;
        }
    }
}
.energyClaimFormContainerUpload,
.energyClaimFormContainer{
    background-color: white;
    padding: 0 0 50px;
    .stepSelected{
        // max-height:0;
        height: auto;
        // transition: ease-in-out 200ms all;
        .divExtender{
            transition: ease 500ms all;
            margin: 0 auto; 
            display: block;
            background-color:$primaryFusion;
            height: 0;
            width: $borderWidth ;
        }
    } 
 
    
}
.energyClaimFormContainerUpload{ 
    padding: 0px; 
}
.fushionContent{
    background-color: whitesmoke;
    // padding-top: 170px;
    padding-bottom: 50px;
    .stepSelected{
        // max-height:0;
        height: auto;
        // transition: ease-in-out 200ms all;
        .divExtender{
            transition: ease 500ms all;
            margin: 0 auto; 
            display: block;
            background-color:$primaryFusion;
            height: 0;
            width: $borderWidth ;
        }
    } 
}
.pageBody{
    text-align: left;
    padding:10px 0 30px;
    h2{
        font-size: 25px;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 15px;
    }
    p, li{
        color: #333;
    }
    li{
        font-weight: 600;
        line-height: 2
    }
} 
.underMaintance{
    z-index:9999999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    h1{
        color: black;
        text-align: center;
        margin: 0;
        font-weight:100
    };
}

.previews{
    display: flex;
    padding-bottom: 70px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    embed{
        border: 1px solid grey;
        border-radius: 9px;
        overflow: hidden;
    }
    a{
        color: grey;
        font-weight: 400;
        text-decoration: none;
        font-style: italic;
        letter-spacing: 1px;;

    }
}

.desktopStyle {
    display: block !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.desktopStyleFlex {
    display: flex !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.mobileStyle {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: block !important;
    }
}

.mobileStyleFlex {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: flex !important;
    }
}