@import '../../../globals/variables.scss';


.greenBar{
    text-align: left;
    background-color: whitesmoke;
    padding: 30px 0;
    margin-bottom: 100px;
}
.greenBarInner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 30px;
    box-sizing: border-box;
    p {
        margin: 0;
        max-width: 35%;
        font-size: 24px;
        font-weight: 600;
        color: $secondaryFusion;

    }
    img{
        max-width: 120px;   
    }
}