@import '../../../globals/variables.scss';

.inputStyle{ 
    width: $inputWidth;
    max-width:  $inputWidthMax;
    display: block;
    line-height: 1.6; 
    box-sizing: border-box; 
    font-size: 17px;
    padding: 3px 5px;
    border-radius: 5px;
    border: 1px solid #717171;
    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width:  100%;
        box-sizing: border-box;
    }
} 

span.notice{
    display: block;
    color: red; 
    font-size: 11px;
}

.lookupContainer{
    position: relative;

}

.lookupSelection{
    position: absolute;
    top: 100%;
    background-color: white;
    border: 1px solid grey;
    width: 100%;
    max-width: 90vw;
    max-height: 300px;
    overflow:scroll;
    box-sizing: border-box;
    text-align: left;
    padding: 0;
    z-index: 999;
    p{
        padding: 5px;
        margin: 0;
        width: 100%;
        background-color: lightgrey;
    }
    ul{
        margin: 0;
        list-style: none;
        padding: 10px;
        li{
            line-height: 1.8;
            border-bottom: 1px solid whitesmoke;
            padding: 5px 5px;
            &:hover{
                cursor: pointer;
                background-color: whitesmoke;
                transition:ease-in-out 200ms all;

            }
        }
    };

}