.sidebyside{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 40px 0 0;
    width: 100%;

    > * {
        margin: 0 10px!important;
    }
}