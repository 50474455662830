@import '../../../../../globals/variables.scss'; 

.footer{
    // margin-top: 40px;
    text-align: left; 
    border-top:4px solid #e47047 ;
    h6{
        margin: 0 0 25px;
        padding-bottom: 10px;
        font-size: 19px;
        border-bottom: 1px solid rgba(0,0,0,0.2);
    }

    .innerFooter{   
        padding: 40px 0 20px;
        width: 100%; 
        margin: 0 auto; 
        background-color: whitesmoke; 
     
        p{
            font-weight: 500; 
            font-size: 13px;  
            margin: 0 0 15px;
            line-height: 1.6;
            color: grey;
        }
    }

    .footerBottom {
        padding-bottom: 30px;
        background-color: whitesmoke;
        .footerBottomLayout{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .logoContainer{
            background-color: white;
            border: 1px solid #e47047;
            height: 150px;
            width: 150px;
            box-sizing: border-box;
            padding: 0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;;
            img{
                max-width: 80%;
                ;
            }
        }
        .footerLinks{
            h6{
                border: none;
                margin-bottom: 0px;
            }
            ul{
                padding: 0;  
                list-style-type: none;;
                li{
                    list-style: none;
                    margin-bottom: 15px;
                    a{
                        border-bottom: 1px solid lightgrey;    
                        color: grey;
                        text-decoration: none;
                        &:before{

                        }
                    }
                }
            }
        }
    }
    .copyright{
        width: 100%;
        display: block;
        border-top: 1px solid #e47047;
        text-align: center;
        p{
            font-size: 14px;
            margin: 0;
            letter-spacing: 1px;;
            padding: 10px 0;
            color: grey;
        }
    }
}
 