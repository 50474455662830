@import '../../../globals/variables.scss';
 
.hero{
    background-image: url('../../../assets/hero.jpg');
    min-height: calc(100vh - 135px);
    // margin-top: 135px;
    height: auto;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-start;

    &:before{
        content:'';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0; 
        right:0;
        background-color: rgba(0,0,0,0.4);
        z-index: 0;
    }

    .heroContent{ 
        position: relative;
        z-index: 1;
        width: 65%;
        text-align: left;
        padding: 100px 0;
        > h1{
            color: whitesmoke;
            font-size: 40px;
            margin: 0;
        }
        > p{
            color: white;
            font-size: 14px;
            margin: 10px 0 10px;
            line-height: 1.3;
            font-style: italic;
        }
       > span{ 
            margin: 0;
            line-height: 1.7;
            color: #438f68;
            width: auto;
            background: $blueRebrand;
            color: white;
            padding: 3px 15px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 10px;
            border-radius: 20px;
        }
    }
}
  