@import '../../../../../globals/variables.scss'; 

.footer{
    // margin-top: 40px;
    background-color: whitesmoke;
    padding: 40px 20px;
    color: white;
    border-top: 1px solid lightgrey;
    img{
        max-width: 120px;
    }

    .innerFooter{   
        width: 100%;
        max-width: $wrapperMaxWidth;
        margin: 0 auto; 
        .disclaimer{ 
            font-size: 12px;
            color: black;
        }
        p{
            font-weight: 400;
            letter-spacing: 0.5px;
            font-size: 13px;
            color: lightgrey;
            a{
                color: black;
                display: inline-block;
                margin-right: 20px;;
            }
        }
    }
    .footerbottom {
        display: flex;
        justify-content: space-between;
        p{
            font-weight: 400;
            a{
                text-decoration: none;
                color: white;
                span{
                    font-weight: 600;

                }
            }
        }
    }
}
 