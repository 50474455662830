@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) { 
   
.centralContainer{
    text-align: center;

    .titleContainer{
        h2{
            font-size: 28px;
        }
        p{
            color: rgb(78, 86, 82);
        }
    }
    .threePoints{
        background-color: transparent;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
        > div{
            padding: 20px;;
            margin-bottom: 20px;
            border-radius: 15px;
            background-color: $secondaryFusion;
            span{
                margin: 0 auto;
                font-size: 40px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                background-color: white;
                color: $secondaryFusion;
            }
            p{  
                &:first-of-type{
                    margin-top: 20px;
                    font-size: 22px;;
                    font-weight: 600;
                    color:white;
                }
                &:last-of-type{
                    color:white;
                    line-height: 1.4;
                }
            }
        }
    }   
}
}