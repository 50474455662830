@import '../../../../../globals/variables.scss';


@media screen and (max-width: 992px) { 
    .headerBorder {
        ul {
            flex-direction: column;
            li{ 
                line-height: 1.8;
            }
        }
    } 
}