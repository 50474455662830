// .inputContainer{
//     display: flex;
//     justify-content: space-between;
// }

.fileInputContainer p{
    font-size: 13px;
    margin: 0;
    max-width: 100%;
}
 
 .label{
    position: relative;
    display: block;
    height: 200px;
    width: 150px;
    background: whitesmoke;
    border-radius: 4px;
    margin-bottom: 15px;
    cursor:pointer;
    border: 1px solid lightgrey;
    
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        object-fit: cover;
        max-width: 40%;
        transform: translate(-50%, -50%);
        max-height: 90%;
        pointer-events: none;

    }
    @media screen and (max-width: 992px) {
        height: 200px;
        width: 100%;
    }
}
input[type=file]{
    visibility: hidden;
    opacity: 0; 
    width: 1px;
    height: 1px;
}