$primary:#B46846;

$darkgreenGradient: linear-gradient(90.08deg, #D5B891 1.32%, #A4713F 100.87%);
$wrapperMaxWidth: 1200px;
$timing: 1500ms;
$bigtiming: 1750ms;
$inputWidth: 100%;
$inputWidthMax:900px;

$primaryFusion: #B46846;
$secondaryFusion: #f75304;
$thirdFusion: #e47047;
$blueRebrand: #5B86C3; 
$textColorFusion: #7A7A7A;
$borderWidth: 7px;