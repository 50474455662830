@import '../../../globals/variables.scss';

@media screen and (max-width: 992px) {  
    .hereToHelpContainer{
        text-align: center;
        .titleContainer{
            h2{
                font-size: 38px;
            }
            p{
                color: rgb(78, 86, 82);
            }
        } 
        p{
            margin-bottom: 40px;
            line-height: 1.8;
            padding-left: 0px!important;
            position: relative;
            img{  
                width: 15px;
                height: 15px;
                margin-right: 5px;
                margin-bottom: -2px;
            }
        }
    }
}