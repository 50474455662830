@import '../../globals/variables.scss';
.applicationSign{    
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 30px 30px 30px; 
    box-sizing: border-box;
    background-color: white;
    border: 1px solid white;
    border-radius: 20px; 
    box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.4);
    &.loading{
        // height: 500px;
        max-height: 100vh;
        width: 100%;
        h1{
            text-transform: capitalize;
        }
        img{
            max-width: 150px;
            margin-top: 30px;
        }
    }
    .applicationSignInner{
        iframe{
            border-radius: 9px;
            margin: 20px; 
        }
    }
    h1{
        margin:0 0 40px;
        // font-size: 20px;
        font-weight: 500;
        color: $primaryFusion;
    }
}