@import '../../../globals/variables.scss';
 
@media screen and (max-width: 992px) { 

.twoColContainer{
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    > div{
        width: 100%;
        padding: 20px 0;
        text-align: left;
        img{
            max-width: 80%;
            margin: 0 auto;
            display: block ;
        }
        p, h2{
            text-align: left;
        }
        h2{
            font-size: 32px;

        }
    }
}
}