@import '../../../../../globals/variables.scss';
 
.header { 
    // position: absolute; 
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    background-color: white;
    z-index:1; 
    img{
        max-width: 100%; 
        @media screen and (max-width: 992px) {
            max-width: 120px;
        }
    }  
}

.innerHeader {  
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 0px;
    .profileContainer{
        background-color: $blueRebrand;
        color: white;
        font-family: 'Open Sans',sans-serif;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-size: 12px;
        padding: 15px 20px;
        border-radius: 50px;
        p{
            margin: 0; 
            font-weight: 600;
            line-height: 1.2;   
        }

    }
    .logoContainer{
        max-width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;;
         
        img{
            margin-right: 40px;
            width: 150px;;
            object-fit: contain;

        }
    } 
} 
.headerBorder{
    padding: 10px;
    background-color: $secondaryFusion;
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;;
        justify-content: center;
        li{
            font-size: 12px;
            color: white;
            text-transform: uppercase;
            margin: 0 40px;
            padding: 0;
            // font-weight: 600;
            letter-spacing: 1px;
            vertical-align: middle;
            span{
                font-size: 16px;    
            }

            &:before {
                content: '';
                height: 15px;
                width: 15px;
                display: inline-block;
                margin-right: 10px;
                background-size: contain;
                vertical-align: text-top;
            }
            &:first-of-type:before {
                background-image: url('../../../../../assets/fusionSign/lock.svg');
            }
            &:nth-of-type(2):before {
                content: none;
             }
            &:nth-of-type(3):before {
                background-image: url('../../../../../assets/fusionSign/currency.svg');
            }
            img{
                max-height: 21px;
                vertical-align:text-bottom;
                margin-right: 5px;
            }
        }
    }
}